@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btnNav {
    @apply px-0 rounded-md;
  }

  .btnNav:hover {
    @apply bg-gray-800;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
    /* apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                                                  'Ubuntu',
                                                    'Cantarell',
                                                    'Fira Sans',
                                                    'Droid Sans',
                                                    'Helvetica Neue',
                                                    'Poppins'
                                                  sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Poppins'
    monospace;
}

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 55, 0, 0.2);
  border-radius: 5px;
  
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #151B4E;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #151B4E;
}

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation { 
  z-index: 11000;
}